@import '@lumapps/lumx/scss/variables.scss';

.like-reaction-button-group {
  display: inline-block;
}

.like-reaction-button {
  // Override the icon color on hover & "liked" state (not in dark theme)
  &--is-liked:not(.lumx-button--color-light),
  &:hover:not(.lumx-button--color-light) {
    .lumx-icon {
      color: $lumx-color-red-N;
    }
  }
}
