@import '@lumapps/lumx/scss/variables.scss';

.block-reactions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: $lumx-spacing-unit;

    &--is-preview-only {
        pointer-events: none;
    }

    &__share-btn {
        // make this specific icon button have the same border radius as other buttons
        &.lumx-button.lumx-button--variant-icon {
            border-radius: var(--lumx-button-border-radius);
        }
    }
}
