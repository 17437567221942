@import '@lumapps/lumx/scss/variables.scss';

// Min width on the user like list popover
.like-button-like-list {
  min-width: $lumx-size-xl !important;
}

// Hide the menu button by default
.like-reaction-button-menu {
  opacity: 0;
  width: 0 !important;

  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 300ms, width 300ms;
  }
}

// Show the menu button on focus visible
.like-reaction-button[data-focus-visible-added] + .like-reaction-button-menu,
.like-reaction-button-menu[data-focus-visible-added] {
  opacity: 1;
  width: $lumx-size-xs !important;
}
